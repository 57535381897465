<!-- 党组织列表页面 -->
<template>
  <div>
    <a-card title="查询区域" style="margin-bottom: 15px">
      <a slot="extra">
        <a-button type="primary" style="margin-right: 10px" @click="search"
          >查询</a-button
        ></a
      >
      <a slot="extra"
        ><a-button type="primary" @click="reset">重置</a-button></a
      >
      <!--  -->
      <ax-form ref="form" :formBuilder="formBuilder" />
    </a-card>
    <div class="btn-group">
      <a-button
        style="background-color: #2a5cff; color: #ffffff; margin-right: 10px"
        @click="add"
        >新增团支部</a-button
      >
      <a-button
        style="
          background-color: #2a5cff;
          color: #ffffff;
          margin-left: 10px;
          margin-right: 10px;
        "
        @click="expor"
        >导出</a-button
      >
      <a-button
        style="
          background-color: #67c23a;
          color: #ffffff;
          margin-left: 10px;
          margin-right: 10px;
        "
        @click="showDiagram"
        >组织架构图</a-button
      >
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      :row-key="(record, index) => record.id"
      :indentSize="50"
      size="middle"
      :scroll="{ x: '100%' }"
    >
      <div slot="pname" slot-scope="text, record">
        {{ record.pname ? record.pname : "--" }}
      </div>

      <div slot="supervisorName" slot-scope="text, record">
        {{ record.supervisorName ? record.supervisorName : "--" }}
      </div>
      <div slot="gridWorkerName" slot-scope="text, record">
        {{ record.gridWorkerName ? record.gridWorkerName : "--" }}
      </div>
      <div slot="buildingCount" slot-scope="text, record">
        {{ record.buildingCount ? record.buildingCount : "--" }}
      </div>
      <div slot="address" slot-scope="text, record">
        {{ record.address ? record.address : "--" }}
      </div>
      <div slot="actions" slot-scope="text, record" class="actions">
        <div class="edit" @click="detailGrid(record)">编辑</div>
        <a-popconfirm
          title="是否确定？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm(record)"
        >
          <div class="delete">删除</div>
        </a-popconfirm>
      </div>
    </a-table>
    <!-- 新增党组织弹窗 -->
    <add-form ref="addOrganization" @refsh="refsh"></add-form>
    <!-- 党组织架构图弹窗 -->
    <diagram ref="diagram"></diagram>
  </div>
</template>

<script>
import mapModal from "./components/mapModal.vue";
import addForm from "./components/addForm.vue";
import mapPoint from "./components/mapPoint.vue";

import api from "./api";
import Diagram from "./components/diagram.vue";
const searchForm = [
  {
    label: "支部名称",
    type: "input",
    model: "keyword",
    options: { placeholder: "支部名称" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  name: "loginLog",
  components: { mapModal, addForm, mapPoint, Diagram },
  data() {
    return {
      api,
      searchForm,
      data: [],
      columns: [
        {
          title: "支部名称",
          dataIndex: "name",
          ellipsis: true,
          width: 260,
          align: "center",
        },
        {
          title: "上级支部",
          dataIndex: "pname",
          ellipsis: false,
          width: 150,
          align: "center",
          scopedSlots: { customRender: "pname" },
        },
        {
          title: "下级支部数",
          dataIndex: "address",
          ellipsis: false,
          align: "center",
          width: 240,
          scopedSlots: { customRender: "address" },
        },
        {
          title: "人数",
          dataIndex: "buildingCount",
          ellipsis: false,
          width: 130,
          align: "center",
          scopedSlots: { customRender: "supervisorName" },
        },
        {
          title: "定位状态",
          dataIndex: "gridWorkerName",
          ellipsis: false,
          width: 130,
          align: "center",
          scopedSlots: { customRender: "gridWorkerName" },
        },
        {
          title: "操作",
          // fixed: "right",
          dataIndex: "actions",
          align: "center",
          width: 180,
          scopedSlots: { customRender: "actions" },
        },
      ],
      searchActions: [
        { name: "add", text: "网格新增", type: "#556bff" },
        { name: "export", text: "导出", type: "#67c23a" },
        { name: "search", text: "查询", type: "#556bff" },
        { name: "reset", text: "重置", type: "#556bff" },
      ],
      formBuilder: this.$common.initGridFormData(
        searchForm,
        { layout: "horizontal", labelWidth: 90 },
        { col: { span: 8 } }
      ),
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.supervisorList();
    this.getList();
  },
  methods: {
    // 组织架构图弹窗
    showDiagram() {
      this.$refs.diagram.openModal()
    },
    // 刷新表格
    refsh() {
      this.getList();
    },
    async getList(value) {
      const res = await api.list(value);
      this.data = res.data;
      console.log(res, "194");
    },
    onShowSizeChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getList(this.pagination.current); //分页查
    },
    // 查询
    search() {
      this.$refs.form.form.validateFields(async (err, value) => {
        this.getList(value);
      });
    },
    reset() {
      this.$refs.form.resetFields();
      this.getList();
    },
    // 删除
    confirm(record) {
      this.popConfirm(record);
    },
    // 点击删除操作的api
    async popConfirm(record) {
      // console.log(record);
      const res = await api.deletegrid(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.getList();
    },
    // 编辑
    detailGrid(record) {
      record.title = "编辑党支部";
      this.$refs.addOrganization.openModal(record);
      // this.formId = record.id;
      // this.titles = "编辑网格";
      // this.visibles = true;
      // this.$nextTick(async () => {
      //   // 表单详情
      //   this.$refs.addform.getgrid(record.id);
      //   // 地图详情
      //   const res = await api.getAtlas(record.id);
      //   if (res.data === "未标绘") {
      //     this.mapValue = null;
      //   } else {
      //     this.mapValue = res.data;
      //   }
      //   // console.log(this.mapValue, "编辑");
      // });
      // if (this.$refs.mapDraw) {
      //   this.$refs.mapDraw.deletes();
      // }
    },
    expor() {
      api.export({
        fileName: "网格管理.xls",
        params: "",
      });
    },
    // 打开弹窗
    add() {
      this.$refs.addOrganization.openModal({ title: "新增党支部" });
      // this.visibles = true;
      // console.log(this.pointForm);
      // this.titles = "新增党支部";
      // this.$nextTick(() => {
      //   this.$refs.addform.getPoint({ Lng: "", Lat: "" });
      //   this.$refs.addform.getgridTree();
      // });
      // if (this.$refs.mapDraw) {
      //   this.$refs.mapDraw.deletes();
      // }
    },
    openMap() {
      this.visible = true;
      if (this.titles === "编辑网格" && this.formId) {
        this.$nextTick(() => {
          this.$refs.mapDraw.getgrid(this.formId);
        });
      } else if (this.titles === "新增网格") {
        // this.$refs.mapDraw.initAMap()
      }
    },
    // 地图提交
    mapSave() {
      // this.$refs.mapDraw.unloadMap();
      this.visible = false;
      this.mapValue = this.$refs.mapDraw.getMaparr();
      if (this.$refs.mapDraw.retMaplist().length) {
        let outputArr = this.$refs.mapDraw.retMaplist().map((points) => {
          return {
            colour: "#a5d2ed",
            points: points.map((point) => {
              return { longitude: point[0], latitude: point[1] };
            }),
          };
        });
        outputArr.forEach((item) => {
          this.mapValue.push(item);
        });
      }
      // this.$refs.mapDraw.deletes()
    },
    retId(e) {
      this.retIds = e;
    },
    // 表单提交
    async formSave() {
      this.formValue = null;
      this.$refs.addform.retList();
      if (this.formValue === null) return;
      let obj = {};
      if (this.mapValue === null) {
        obj = {
          ...this.formValue,
          graphList: null,
          longitude: this.pointForm.Lng,
          latitude: this.pointForm.Lat,
        };
      } else {
        obj = {
          ...this.formValue,
          graphList: this.mapValue,
          longitude: this.pointForm.Lng,
          latitude: this.pointForm.Lat,
        };
      }
      if (this.titles === "编辑网格" && this.formId) {
        obj.id = this.formId;
        obj.isDraw = this.retIds;
      }
      this.saveType = true;
      try {
        const res = await api.addGrid(obj);
        if (res.status === 200) {
          this.$message.success("操作成功");
          this.saveType = false;
          this.$refs.addform.resetForm();
          this.visibles = false;
          this.getList();
          this.pointForm = {};
        } else {
          this.$message.error("操作失败");
        }
      } catch (error) {
        console.error(error); // 处理错误
        this.saveType = false;
      }
    },
    retValue(e) {
      this.formValue = e;
    },
    formCancel() {
      this.mapValue = null;
      // this.mapValue = this.$refs.mapDraw.getMaparr();
      this.visibles = false;
      this.$refs.addform.resetForm();
      this.pointForm = {};
    },
    async supervisorList() {
      try {
        const res = await api.superVisorList();
        if (res.data) {
          let options = res.data.map((item) => {
            return {
              label: item.gridWorkerName,
              value: item.gridWorkerId,
            };
          });
          this.$nextTick(() => {
            // console.log(options);
            this.$refs.form.setFormItemProp("gridWorkerId", {
              options: {
                options,
                placeholder: "请选择网格负责人",
                showSearch: true,
                mode: "multiple",
                optionFilterProp: "label",
                labelInValue: false,
              },
            });
            // this.$refs.form.searchForm.setFormItemProp(
            //   "gridWorkerId",
            //   {
            //     options: {
            //       options,
            //       placeholder: "请选择网格负责人",
            //       showSearch: true,
            //       // optionFilterProp: "label",
            //       mode: "multiple",
            //       optionFilterProp: "label",
            //       labelInValue: false,
            //     },
            //   }
            // );
          });
        }
      } catch (error) {}
    },
    // 标点弹窗
    openPoint() {
      this.visiblePoint = true;
    },
    pointSave() {
      if (!this.$refs.mapPoint.retPoint().Lng) {
        this.$message.error("请添加标点");
        return;
      }
      this.visiblePoint = false;
      this.pointForm = this.$refs.mapPoint.retPoint();
      // 清空地图
      this.$refs.mapPoint.unloadMap();
      this.$refs.addform.getPoint(this.pointForm);
    },
    pointCancel() {
      this.visiblePoint = false;
      this.$refs.mapPoint.unloadMap();
    },
  },
};
</script>

<style scoped lang='less'>
.actions {
  display: flex;
  justify-content: space-evenly;
  .edit {
    color: #556bff;
    cursor: pointer;
  }
  .delete {
    color: #f95a5a;
    cursor: pointer;
  }
}
.btn-group {
  margin-bottom: 0.6%;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #f5f7fb;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
/deep/ .ant-table-bordered .ant-table-fixed-right table {
  border-left: none;
}
/deep/.ant-card {
  border-radius: 4px;
}
/deep/.ant-card-head {
  border-radius: 4px;
  background-color: #fafafa;
}
</style>
